var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page page-user-panel"},[_c('user-panel-layout',{attrs:{"active":"users"}},[_c('section',{staticClass:"panel-module panel--users",style:({
      backgroundColor:
        'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
    })},[_c('div',{staticClass:"panel-module--header"},[_c('h4',{staticClass:"panel-module--header__title"},[_vm._v(" "+_vm._s(_vm.$t("message.admin.panels.users"))+" ")])]),_c('div',{staticClass:"panel-module--body",class:[_vm.loading ? 'overflow-hidden' : ''],style:({
        backgroundColor:
          'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
      })},[(_vm.loading)?_c('panel-module-loader',{attrs:{"color":this.$getUserColor(this.selectedColor).dark,"bgColor":this.$getUserColor(this.selectedColor).light}}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"panel--users--table"},[_c('div',{staticClass:"messages--user-list"},_vm._l((_vm.users),function(item,index){return _c('user-status-card',{key:index,attrs:{"avatar":"","user":item.username,"id":item.id,"status":item.enabled,"color":_vm.selectedColor,"message":item.enabled ? 'Aprobado' : 'SIN APROBAR',"role":item.role},on:{"toggle":_vm.selectUser}})}),1)]):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }