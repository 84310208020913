const messages = {
  ca: {
    message: {
      general: {
        close: "Tancar",
        archive: "Arxiu",
        required: "Obligatori",
        image: "Imatge",
        images: "Imatges",
        mainImages: "Imatges principals",
        delete: "Esborrar",
        openImage: "Obrir imatge",
        downloadDossier: "Descarregar dossier",
      },
      inprogress: {
        title: "web en procés...",
        subtitle: "ho sentim! estem treballant en la web",
        button: "Descarregar nostra guía GVA</br>(PDF)",
      },
      menu: {
        items: ["Sobre Cug", "Arxiu", "Map", "Contacte", "Entrar"],
      },

      footer: {
        title:
          "Subscriu-te a la nostra NEWSLETTER per a conéixer totes les novetats sobre el CUG",
        socials:
          "Estigues al dia de conferències, esdeveniments i notícies relacionades amb la càtedra.",
      },
      components: {
        overlayCard: {
          title: "Visita els projectes d'aquesta línia de treball",
        },
        archiveFilter: {
          year: "Any",
          status: "Estatus",
          search: "Buscar por palabras",
        },
        projectSingle: {
          description: "Descripció",
          shortDescription: "Descripció curta",
          tipology: "Tipologia",
          status: "Estatus",
          links: "Links del projecte",
          externalLink: "Link extern",
          file: "Fitxa",
          showInMap: "Visualitza-ho en el geolocalizador",
          download: "Descarregar ací (PDF)",
          edition: "Edició",
          prize: "Premie per la GVA",
          mention: "Esment  per la GVA",
          comments: "Comentaris",
          noComments: "No hi ha comentaris",
          longDescription: "Descripció llarga",
          recognisment: "Reconeixement",
          noRecognisment: "No hi ha reconeixement",
          location: "Localització",
          authors: "Autors/es",
          addComment: "Afegeix un comentari",
          year: "Any",
          projectImages: "Imatges del projecte",
        },
      },
      categories: {
        1: {
          title: "Sostenibilitat i canvi climàtic",
          description:
            "La cura del medi ambient és una condició indispensable per a mantindre un estat de salut adequat de manera col·lectiva. La sobreexplotació i esgotament de recursos mediambientals produeix efectes físics constatables. Aquesta modificació de l'ecosistema global és percebut i patit de manera diferent per cadascuna de les persones afectades, empitjorant les situacions de precarietat o vulnerabilitat preexistents, i en aqueix sentit el canvi climàtic té conseqüències espacialment desfavorables en dones i xiquetes i xiquets.",
        },
        2: {
          title: "Equipaments i infraestructures",
          description:
            "Entenent la noció d'infraestructures assemblades, no com a nova infraestructura sinó com una mirada d'anàlisi de gènere sobre les infraestructures ja existents. Es persegueix no sols desenvolupar o incorporar aquelles infraestructures que es vinculen a la cura i a la proximitat habitualment, necessàries en tot cas, sinó fer una lectura transversal de totes les infraestructures des d'aquesta perspectiva, inclòs l'espai públic entés també com a infraestructura.",
        },
        3: {
          title: "Espais comuns",
          description:
            "Els espais comuns i de proximitat com aquells espais interconnectats per una secció contínua, transició gradual o continuum espacial que acompanya a les persones des de l'habitatge fins al territori. Aquests estan interconnectats amb la resta de xarxes, vertebrant i relacionant al seu torn el conjunt del territori. Es proposa una transició enllaçada que acompanye a les persones en totes les escales de la mobilitat territorial, però que es defineix per la vinculació clara de proximitat entre els espais associats a l'edifici i els associats a escala de barri.",
        },
        4: {
          title: "Seguretat, confort i qualitat urbana",
          description:
            "La seguretat és un requisit essencial que ha d'estar incorporat en totes les actuacions, per a totes les persones, en tots els àmbits. La xarxa d'espais públics, en particular els carrers, es presenta com una infraestructura d'espai social natural, accessible i assequible per a tots. No obstant això, en la majoria dels processos participatius sorgeix amb freqüència la qüestió de la qualitat de l'entorn urbà, caracteritzat mitjançant aspectes parcials com el manteniment i cura de les condicions materials d'aquests espais.",
        },
        5: {
          title: "Gestió social, economia i política",
          description:
            "El planejament urbà exposa solucions en l'espai físic que afecten el conjunt de la població i que, necessàriament, van acompanyades de processos de gestió tant política com administrativa i econòmica. Aquesta perspectiva pot requerir la intervenció de professionals d'altres àrees de coneixement que procuren una aproximació multidisciplinària, així com l'organització de processos participatius. Amb caràcter general, se suggereix tendir a la formació d'equips paritaris, així com, també a títol general, procurar equips de treball diversos",
        },
      },
      forms: {
        questions: {
          creator: {
            1: {
              title: "Quin és el teu perfil?",
            },
            2: {
              title: "Quina és la teua ocupació?",
            },
            3: {
              title: "Indica'ns les teues dades personals",
            },
            4: {
              title: "Moltes gràcies per registrar-te!",
            },
          },
          institution: {
            1: {
              title: "Quin és el teu perfil?",
            },
            2: {
              title: "A quina província pertanys?",
            },
            3: {
              title: "Indica'ns en el municipi en el què et trobes",
            },
            4: {
              title: "Com vas conéixer la plataforma del *CUG?",
            },
            5: {
              title: "Com vas conéixer la plataforma del *CUG?",
            },
            6: {
              title: "Quines busques en la plataforma?",
            },
            7: {
              title: "Finalment, indica'ns el teu correu electrònic",
            },
            8: {
              title: "Moltes gràcies per registrar-te!",
            },
          },
          upload: {
            titles: {
              1: "Completa les dades resumeixen del teu projecte",
              2: "El teu projecte ha sigut reconegut per la Generalitat Valenciana?",
              3: "Afig més informació sobre el teu projecte",
              4: "T'agraïm que hages pujat un projecte!",
            },
            subtitles: {
              1: "Recorda emplenar els camps en valencià i castellà",
              2: "Fes-nos-ho saber per a posar el teu reconeixement en la plantilla de projecte!",
              3: "(Aquesta informació es convertirà en un pdf descargable)",
              4: "",
            },
            goToMyProfile: "Anar al meu perfil",
          },
          edit: {
            titles: {
              1: "Completa les dades resumeixen del teu projecte",
              2: "Configura les imatges del projecte",
              3: "El teu projecte ha sigut reconegut per la Generalitat Valenciana?",
              4: "Afig més informació sobre el teu projecte",
              5: "¡El teu projecte ha sigut editat amb èxit!",
            },
            subtitles: {
              1: "Recorda emplenar els camps en valencià i castellà",
              2: "Selecciona les imatges principals del projecte i les secundàries",
              3: "Fes-nos-ho saber per a posar el teu reconeixement en la plantilla de projecte!",
              4: "(Aquesta informació es convertirà en un pdf descargable)",
              5: "",
            },
            goToMyProfile: "Anar al meu perfil",
            seeProject: "Veure el projecte",
            editAnotherProject: "Editar un altre projecte",
          },
          editAdmin: {
            titles: {
              1: "Completa les dades resumeixen del teu projecte",
              2: "Configura les imatges del projecte",
              3: "El teu projecte ha sigut reconegut per la Generalitat Valenciana?",
              4: "Afig més informació sobre el teu projecte",
              5: "¿Estàs d'acord amb la informació revisada d'aquest projecte?",
              6: {
                approved: "¡El projecte ha sigut editat amb èxit!",
                denied: "El projecte s'ha posat en espera d'aprovació",
              },
            },
            subtitles: {
              1: "Recorda emplenar els camps en valencià i castellà",
              2: "Selecciona les imatges principals del projecte i les secundàries",
              3: "Fes-nos-ho saber per a posar el teu reconeixement en la plantilla de projecte!",
              4: "(Aquesta informació es convertirà en un pdf descargable)",
              5: "",
              6: "",
            },
            seeProject: "Veure el projecte",
            editAnotherProject: "Editar un altre projecte",
            approveProject: "Aprovar projecte",
            leaveOnHold: "Deixar en espera",
            goToMyProfile: "Anar al meu perfil",
          },
          confirmPasswordGreetings: {
            title: "Benvingut/da a la plataforma del CUG!",
            subtitle:
              "Per poder accedir al teu compte, un administrador ha de confirmar les teues dades. T'enviarem un correu electrònic quan el teu compte estigui actiu.",
            changedPass: "La teua contrasenya s'ha canviat correctament",
            recoverPassMessage:
              "S'ha enviat un correu electrònic amb les instruccions per a restablir la teua contrasenya",
          },
        },

        isCorrect: "És correcta aquesta informació?",
        submit: "Enviar",
        yes: "Sí",
        no: "No",
        authors: "Autors/es",
        authorsCat: "Autors/es en valencià",
        authorsEs: "Autors/es en castellà",
        projectNameEs: "Nom del projecte en castellà",
        projectNameCat: "Nom del projecte en valencià",
        year: "Any",
        status: "Estat",
        tipology: "Tipologia",
        projectShortDescription: "Descripció del projecte",
        projectShortDescriptionCat: "Descripció del projecte en valencià",
        projectShortDescriptionEs: "Descripció del projecte en castellà",
        projectCoordinates: "Coordenades del teu projecte",
        generatePassword: "Genera la contrasenya per al teu compte",
        resetPassword:
          "Indica el teu correu electrònic per a restablir la teua contrasenya",
        previous: "Tornar",
        next: "Continuar",
        login: "Inici de sessió",
        loginAction: "Iniciar sessió",
        email: "Correu electrònic",
        password: "Contrasenya",
        forgotPassword: "Feix oblidat la teua contrasenya?",
        noAccount: "Si encara no tens compte, ",
        noAccountLink: "registra't ací",
        profileQuestion: "Quin és el teu perfil?",
        profileCreator: "Perfil creatiu",
        profileInstitution: "Perfil institucional",
        jobQuestion: "Quina és la teua ocupació?",
        student: "Estudiant",
        professional: "Professional",
        company: "Empresa",
        provinceQuestion: "A quina província pertanys?",
        personalDataQuestion: "Indica'ns les teues dades personals",
        alicante: "Alacant",
        castellon: "Castelló",
        valencia: "València",
        nameAndSurname: "Nom i apellidos",
        companyName: "Nom d'empresa",
        pronouns: "Pronoms",
        pronounHim: "Ell",
        pronounHer: "Ella",
        pronounThey: "Elle",
        municipalityQuestion: "Indica'ns en el municipi en el què et trobes",
        municipalitySelect: "Selecciona el teu municipi",
        finalDataQuestion: "Finalment, completa els teus dades",
        newPassword: "Nova contrasenya",
        repeatPassword: "Repeteix la contrasenya",
        meetQuestion: "Com vas conéixer la plataforma del *CUG?",
        meetAnswer1: "Xarxes socials",
        meetAnswer2: "Pel meu treball",
        meetAnswer3: "Per recomanació",
        meetAnswer4: "Una altra raó",
        thanksRegister: "Moltes gràcies per registrar-te!",
        thanksRegister2:
          "En breus rebràs un correu per part de l'equip de *CUG per a poder generar una contrasenya i pujar projectes",
        backToHome: "Anar a la pàgina d'inici",
        thanksUpload: "T'agraïm que hages pujat un projecte!",
        seeProfile: "Veure el meu perfil",
        uploadProject: "Pujar projecte",
        lookForQuestion: "Quines busques en la plataforma?",
        emailQuestion: "Finalment, completa els teus dades",
        completeData: "Completa les dades resum del teu projecte",
        maxCharacters200: "(Màxim 200 caràcters)",
        maxCHaracters300: "(Màxim 300 caràcters)",
        maxCharacters2000: "(2000 caràcters màx)",
        projectTitle: "Títol del projecte",
        projectYear: "Any",
        projectTipology: "Tipologia del projecte",
        projectState: "Estat del projecte",
        projectDescription: "Descripció del projecte",
        projectSelectFiles: "Selecciona els arxius",
        maxFilesOne: "(1 màxim)",
        maxFilesThree: "(3 màxim)",
        maxFilesTen: "(10 màxim)",
        recognitionQuestion:
          "¿Tu proyecto ha sido reconocido por la Generalitat Valenciana?",
        recognitionDescription:
          "¡Háznoslo saber para poner tu reconocimiento en la plantilla de proyecto!",
        edition: "Edició",
        recognition: "Reconocimiento",
        recognitionLink: "Link a la página del proyecto",
        coordinatesHelpQuestion: "Com obtindre les coordenades?",
        coordinatesHelpText1:
          "En google maps, col·locar la localització del projecte.",
        coordinatesHelpText2:
          "Fer click dret sobre el pin de localització i copiar les coordenades.",
        projectMoreInfoUpload: "Afig més informació sobre el teu projecte",
        projectMoreInfoDescription:
          "(Esta informació es convertirà en un pdf descarregable)",
        descriptiveText: "Text descriptiu",
        projectLink: "Enllaç del projecte",
        projectDescriptionEs: "Descripció del projecte en castellà",
        projectDescriptionCat: "Descripció del projecte en valencià",
        emailRequired: "El correu electrònic és obligatori",
        emailInvalid: "El correu electrònic no és vàlid",
        passwordRequired: "La contrasenya és obligatòria",
        passwordInvalid: "La contrasenya no és vàlida",
        passwordConfirmRequired:
          "La confirmació de la contrasenya és obligatòria",
        passwordMinLength: "La contrasenya ha de tenir almenys 8 caràcters",
        passwordMaxLength: "La contrasenya no pot tenir més de 20 caràcters",
        passwordUppercase: "La contrasenya ha de tenir almenys una majúscula",
        passwordLowercase: "La contrasenya ha de tenir almenys una minúscula",
        passwordNumber: "La contrasenya ha de tenir almenys un número",
        passwordSpecialCharacter:
          "La contrasenya ha de tenir almenys un caràcter especial",
        passwordConfirmMatch: "Les contrasenyes no coincideixen",
        municipalityRequired: "El municipi és obligatori",
        fullNameRequired: "El nom i cognoms són obligatoris",
        fullNameMaxLength:
          "El nom i cognoms no poden tenir més de 50 caràcters",
        pronounsRequired: "Els pronoms són obligatoris",
        companyRequired: "El nom de l'empresa és obligatori",
        companyMaxLength:
          "El nom de l'empresa no pot tenir més de 50 caràcters",
        projectShortDescriptionMax:
          "La descripció curta no pot tenir més de 300 caràcters",
        requiredField: "Aquest camp és obligatori",
        uploadMainImage: "Pujar imatge principal",
        uploadImage: "Pujar imatge",
      },
      home: {
        main: {
          1: "Incorporar la <em>perspectiva de gènere</em> en l'entorn que habitem connecta la ",
          2: "sostenibilitat* ",
          3: "i les",
          4: "infraestructures*",
          5: ` necessàries per a una ciutat `,
          6: "d'espais comuns*",
          7: `considerant `,
          8: "l'habitabilitat* ",
          9: " des de la ",
          10: "transversalitat*",
        },
        walkthrough: {
          1: {
            title: "Explora el nostre arxiu",
            description:
              "<b>Arxiu de Projectes.</b> Dins de l'arxiu es poden visualitzar projectes pujats pels perfils creatius i institucionals relacionats amb les nostres línies de treball.",
            button: "Accedeix ací",
          },
          2: {
            title: "Localitza projectes",
            description:
              "<b>Geolocalización.</b> Cada un dels projectes es poden ubicar dins del nostre mapa geolocalizador de la Comunitat Valenciana.",
            button: "Accedeix ací",
          },
          3: {
            title: "Registra't i participa",
            description:
              "<b>Crega el teu compte.</b> Per a poder formar part de la comunitat i pujar projectes, és necessari la creació d'un compte. Açò permetrà interactuar amb la resta de persones que estiguen registrades en la nostra web.",
            button1: "Registrarme",
            button2: "inicia sesión",
          },
        },
        guide: {
          title: "Descàrrega la nostra guia",
          description:
            "Pots descarregar el pdf de la nostra <b>guia per a incorporar la perspectiva de gènere en actuacions urbanes.</b> En esta pots aprofundir més sobre les nostres tipologies de projectes.",
          button: "Descarregar guia",
          guide: "Guia",
        },
        info: {
          1: {
            title: "Què és CUG?",
            description1:
              "La nova <b>CUG Cátedra Urbanisme i Gènere</b> és fruit d'un conveni entre GVA i UPV per a difondre i facilitar l'accés a la noció i procés d'incorporació de la perspectiva de gènere en l'entorn urbà que habitem *compartidamente.",
            description2:
              "L'objectiu principal de la càtedra és actuar d'interfície perquè el coneixement disponible es puga aplicar de manera pràctica a les nostres ciutats i poblacions, i simultàniament, les experiències reals servisquen per a generar nou coneixement aplicable que es puga disseminar. És en el trenat de pràctica i teoria, amb les persones implicades, on s'espera un mètode de treball més eficaç.",
          },
          2: {
            title: "Per què existim?",
            description:
              "<p>La CUG Catedra Urbanisme i Gènere naix amb un caràcter obertament participatiu, com un punt de trobada d'opinions i referents sobre la perspectiva de gènere en l'urbanisme.</p><p>Una de les activitats de la nova CUG Càtedra Urbanisme i Genere serà la difusió de la guia per a l'aplicació de la perspectiva de gènere en actuacions urbanes de GVA, per a facilitar la seua aplicació progressiva en els entorns on vivim, així com per a recollir diferents possibilitats que puguen ser incorporades a aquesta guia en un futur.</p>",
          },
        },
        worklines: {
          title: "Línies de treball",
          description:
            "Continguts bàsics sobre els quals s'hauria de reflexionar i debatre amb la finalitat d'incorporar la perspectiva de gènere en l'entorn construït.",
        },
        download: {
          title: "Descàrrega Guia GVA",
          description:
            "Pots descarregar el pdf de la nostra guia per a incorporar la perspectiva de gènere en actuacions urbanes. En aquesta pots aprofundir més sobre les nostres tipologies de projectes.",
          button: "Descarregar",
        },
        contact: {
          socials: {
            title: "Segueix-nos en RR.SS",
            description:
              "Estigues al dia de conferències, esdeveniments i notícies relacionades amb la càtedra.",
          },
          mail: {
            title: "Contacta amb CUG",
            description:
              "Si tens alguna proposta, dubte o comentari, contacta amb el següent enllaç.",
            button: "Contacta'ns",
          },
        },
      },
      about: {
        title: "Què és CUG?",
        youtube: {
          title: "El nostre canal de Youtube",
          description:
            "En aquest canal podràs trobar vídeos sobre la CUG, des de les nostres activitats fins a les nostres col·laboracions amb altres entitats.",
        },
        blog: {
          title: "Visita el nostre blog",
          description:
            "Estigues al dia amb les nostres últimes novetats i projectes al nostre blog de wordpress",
          button: "Vés al nostre Blog",
        },
        contact: {
          title: "Connecta't i construïm ciutats igualitàries",
        },
        team: {
          title: "Comissió de seguiment UPV-GVA 2024",
          gva: `<li>Miguel Ángel Ivorra, Director general d'Urbanisme, Paisatge i Avaluació Ambiental</li>
          <li>Emilio Argente, Subdirector d'Urbanisme</li>
          <li>Caridad García, Servei d'Informació i Anàlisi Urbanística</li>`,
          upv: `<li>Ivan Cabrera, Director de l'ETS Arquitectura</li>
          <li>Ana Portalés, Subdirectora de Càtedres d'Empresa i Comunicació ETSA (en representació de la Vicerrectora d'Ocupació i Formació Permanent, M. Dolores Salvador)</li>
          <li>Eva Álvarez, directora CUG</li>
          <li>Carlos Gómez, director adjunt CUG (amb veu i sense vot)</li>`,
        },
      },
      download: {
        cooldown: "Si us plau, espera abans de descarregar de nou.",
      },
      archive: {
        title: "Arxiu",
        subtitle:
          "<span>de projectes amb</span> perspectiva</br>d'urbanisme i gènere",
        noResults: "No hi ha resultats",
      },
      contact: {
        title: "Contacte",
        subtitle: "¿Vols posar-te en contacte amb el nostre equip?",
        subtitle2: "Envia'ns un missatge!",
        nameAndSurname: "Nom i cognoms",
        email: "Correu electrònic",
        message: "Missatge",
        phone: "Telèfon",
        subject: "Assumpte",
        send: "Enviar",
        optional: "Opcional",
        greetings: "Gràcies per contactar amb nosaltres!",
        greetings2: "Missatge enviat. En breu ens posarem en contacte amb tu.",
      },
      map: {
        modal: {
          helpTitle: "Ajuda",
          help1:
            "Haga clic en cualquier chincheta de ubicación en el mapa para mostrar más información sobre el proyecto.",
          help2:
            "Para acercarse o alejarse simplemente desplácese o utilice los botones de zoom situados en la parte inferior derecha del mapa.",
        },
        seeProject: "Veure projecte",
      },
      admin: {
        menu: {
          items: [
            "Inici",
            "Projectes",
            "Missatges",
            "Estadístiques",
            "Usuaris",
          ],
        },
        saved: "Guardats",
        created: "Creats",
        administrative: "Administratiu",
        uploadNewProject: "Pujar nou projecte",
        newConversation: "Nova conversa",
        searchInMessages: "Cercar en missatges",
        edit: "Editar",
        writeAMessage: "Escriu un missatge",
        send: "Enviar",
        back: "Tornar",
        cancel: "Cancel·lar",
        save: "Guardar",
        accept: "Acceptar",
        pickColor: "Escull un color",
        panels: {
          yourZoneActivity: "Activitat en la teua zona",
          notifications: "Notificacions",
          insightsResume: "Resum d'estadístics",
          insights: "Estadístics",
          yourProjects: "Els teus projectes",
          yourMessages: "Els teus missatges",
          messages: "Missatges",
          newConversation: "Nova conversa",
          projectRecommendation: "Recomanació de projectes",
          lastIncorporation: "Últims projectes pujats",
          approvedProjects: "Projectes aprovats",
          pendingProjects: "Projectes pendents",
          users: "Usuaris",
          for: "Per...",
          startChat: "Iniciar chat",
        },
        insights: {
          visualizations: "Visualitzacions",
          saved: "Guardades",
          likes: "Likes",
          comments: "Comentaris",
          yourProjects: "Els teus projectes",
        },
        notifications: {
          approved: "ha aprovat el teu projecte",
          rejected: "ha rebutjat el teu projecte",
          saved: "ha guardat el teu projecte",
          liked: {
            prepend: "",
            append: "ha posat like al teu projecte",
          },
          commented: "ha comentat el teu projecte",
          timestamp: {
            prepend: "Fa",
            seconds: "segons",
            minutes: "minuts",
            hours: "hores",
            days: "dies",
            months: "mesos",
            years: "anys",
            more: "més d'un any",
          },
        },
        projects: {
          published: "Publicat",
          pendant: "Pendent",
          edit: "Editar",
        },
      },
    },
  },
  es: {
    message: {
      general: {
        close: "Cerrar",
        archive: "Archivo",
        required: "Campo obligatorio",
        image: "Imagen",
        images: "Imágenes",
        mainImages: "Imágenes principales",
        delete: "Eliminar",
        openImage: "Abrir imagen",
        downloadDossier: "Descargar dossier",
      },
      inprogress: {
        title: "web en proceso...",
        subtitle: "lo sentimos! estamos trabajando en la web",
        button: "Descargar nuestra guía GVA</br>(PDF)",
      },
      menu: {
        items: [
          "Sobre Cug",
          "Archivo",
          "Mapa",
          "Contacto",
          "Entrar",
        ],
      },
      footer: {
        title:
          "Subscríbete a nuestra NEWSLETTER para conocer todas las novedades sobre el CUG",
        socials:
          "Mantente al día de conferencias, eventos y noticias relacionadas con la cátedra.",
      },
      components: {
        overlayCard: {
          title: "Visita los proyectos de esta línea de trabajo",
        },
        archiveFilter: {
          year: "Año",
          status: "Estatus",
          search: "Buscar por palabras",
        },
        projectSingle: {
          description: "Descripción",
          shortDescription: "Descripción corta",
          tipology: "Tipología",
          status: "Estatus",
          links: "Links del proyecto",
          externalLink: "Link externo",
          file: "Ficha",
          showInMap: "Visualízalo en el geolocalizador",
          download: "Descargar aquí (PDF)",
          edition: "Edición",
          prize: "Premio por la GVA",
          mention: "Mención per la GVA",
          comments: "Comentarios",
          noComments: "No hay comentarios",
          longDescription: "Descripción larga",
          recognisment: "Reconocimiento",
          noRecognisment: "No hay reconocimientos",
          location: "Ubicación",
          authors: "Autoras/es",
          addComment: "Añadir un comentario",
          year: "Año",
          projectImages: "Imágenes del proyecto",
        },
      },
      categories: {
        1: {
          title: "Sostenibilidad y cambio climático",
          description:
            "El cuidado del medio ambiente es una <b>condición indispensable</b> para mantener un estado de salud adecuado de manera colectiva. La sobreexplotación y agotamiento de recursos medioambientales produce efectos físicos constatables. Esta modificación del ecosistema global es percibido y sufrido de manera diferente por cada una de las personas afectadas, empeorando las situaciones de precariedad o vulnerabilidad preexistentes, y en ese sentido el cambio climático tiene <b>consecuencias espacialmente desfavorables</b> en mujeres y niñas y niños.",
        },
        2: {
          title: "Equipamientos e infraestructuras",
          description:
            "Entendiendo la noción de infraestructuras ensambladas, no como nueva infraestructura sino como una mirada de <b>análisis de género sobre las infraestructuras ya existentes.</b> Se persigue no solo desarrollar o incorporar aquellas infraestructuras que se vinculan al cuidado y a la proximidad habitualmente, necesarias en todo caso, sino hacer una <b>lectura transversal</b> de todas las infraestructuras desde esta perspectiva, incluido el espacio público entendido también como infraestructura.",
        },
        3: {
          title: "Espacios comunes",
          description:
            "Los espacios comunes y de proximidad como aquellos <b>espacios interconectados</b> por una sección continua, transición paulatina o <b>continuum espacial</b> que acompaña a las personas desde la vivienda hasta el territorio. Estos están <b>interconectados</b> con el resto de redes, vertebrando y relacionando a su vez el conjunto del territorio. Se propone una <b>transición enlazada</b> que acompañe a las personas en todas las escalas de la movilidad territorial, pero que se define por la vinculación clara de proximidad entre los espacios asociados al edificio y los asociados a escala de barrio.",
        },
        4: {
          title: "Seguridad, confort y calidad urbana",
          description:
            "La <b>seguridad</b> es un requisito esencial que debe estar incorporado en todas las actuaciones, para todas las personas, en todos los ámbitos. La red de espacios públicos, en particular las calles, se presenta como una <b>infraestructura de espacio social natural, accesible y asequible</b> para todos.  Sin embargo, en la mayoría de los procesos participativos surge con frecuencia la cuestión de la calidad del entorno urbano, caracterizado mediante aspectos parciales como el mantenimiento y cuidado de las condiciones materiales de dichos espacios.",
        },
        5: {
          title: "Gestión social, economía y política",
          description:
            "El planeamiento urbano expone soluciones en el espacio físico que afectan al conjunto de la población y que, necesariamente, van acompañadas de procesos de gestión tanto <b>política</b> como <b>administrativa y económica</b>. Esta perspectiva puede requerir la intervención de profesionales de otras áreas de conocimiento que procuren una aproximación <b>multidisciplinar</b>, así como la organización de procesos participativos. Con carácter general, se sugiere tender a la formación de equipos paritarios, así como, también a título general, procurar equipos de trabajo diversos.",
        },
      },
      forms: {
        questions: {
          creator: {
            1: {
              title: "¿Cuál es tu perfil?",
            },
            2: {
              title: "¿Cuál es tu ocupación?",
            },
            3: {
              title: "Indícanos tus datos personales",
            },
            4: {
              title: "¡Muchas gracias por registrarte!",
            },
          },
          institution: {
            1: {
              title: "¿Cuál es tu perfil?",
            },
            2: {
              title: "¿A qué provincia perteneces?",
            },
            3: {
              title: "Indicanos en el municipio en el qué te encuentras",
            },
            4: {
              title: "¿Cómo conociste la plataforma del CUG?",
            },
            5: {
              title: "¿Cómo conociste la plataforma del CUG?",
            },
            6: {
              title: "¿Qué buscas en la plataforma?",
            },
            7: {
              title: "Por último, indícanos tu correo electrónico",
            },
            8: {
              title: "¡Muchas gracias por registrarte!",
            },
          },
          upload: {
            titles: {
              1: "Completa los datos resumen de tu proyecto",
              2: "¿Tu proyecto ha sido reconocido por la Generalitat Valenciana?",
              3: "Añade más información sobre tu proyecto",
              4: "¡Te agradecemos que hayas subido un proyecto!",
            },
            subtitles: {
              1: "Recuerda rellenar los campos en valencià y castellano",
              2: "¡Háznoslo saber para poner tu reconocimiento en la plantilla de proyecto!",
              3: "(Esta información se convertirá en un pdf descargable)",
              4: "",
            },
            goToMyProfile: "Ir a mi perfil",
          },
          edit: {
            titles: {
              1: "Completa los datos resumen de tu proyecto",
              2: "Configura las imágenes del proyecto",
              3: "¿Tu proyecto ha sido reconocido por la Generalitat Valenciana?",
              4: "Añade más información sobre tu proyecto",
              5: "¡Tu proyecto ha sido editado con éxito!",
            },
            subtitles: {
              1: "Recuerda rellenar los campos en valencià y castellano",
              2: "Selecciona las imágenes principales del proyecto y las secundarias",
              3: "¡Háznoslo saber para poner tu reconocimiento en la plantilla de proyecto!",
              4: "(Esta información se convertirá en un pdf descargable)",
              5: "",
            },
            goToMyProfile: "Ir a mi perfil",
            seeProject: "Ver proyecto",
            editAnotherProject: "Editar otro proyecto",
          },
          editAdmin: {
            titles: {
              1: "Completa los datos resumen de tu proyecto",
              2: "Configura las imágenes del proyecto",
              3: "¿Tu proyecto ha sido reconocido por la Generalitat Valenciana?",
              4: "Añade más información sobre tu proyecto",
              5: "¿Estás de acuerdo con la información revisada de este proyecto?",
              6: {
                approved: "¡El proyecto ha sido editado con éxito!",
                denied: "El proyecto se ha puesto en espera de aprobación",
              },
            },
            subtitles: {
              1: "Recuerda rellenar los campos en valencià y castellano",
              2: "Selecciona las imágenes principales del proyecto y las secundarias",
              3: "¡Háznoslo saber para poner tu reconocimiento en la plantilla de proyecto!",
              4: "(Esta información se convertirá en un pdf descargable)",
              5: "",
              6: "",
            },
            seeProject: "Ver proyecto",
            editAnotherProject: "Editar otro proyecto",
            approveProject: "Aprobar proyecto",
            leaveOnHold: "No, dejar en espera",
            goToMyProfile: "Ir a mi perfil",
          },
          confirmPasswordGreetings: {
            title: "¡Bienvenido/a a la plataforma del CUG!",
            subtitle:
              "Para poder acceder a tu cuenta, un administrador debe confirmar tus datos. Te enviaremos un correo electrónico cuando tu cuenta esté activa.",
            changedPass: "¡Tu contraseña ha sido cambiada con éxito!",
            recoverPassMessage:
              "Te hemos enviado un correo electrónico con las instrucciones para restablecer tu contraseña.",
          },
        },
        submit: "Enviar",
        yes: "Sí",
        no: "No",
        isCorrect: "¿Es correcta esta información?",
        authors: "Autoras/es",
        authorsCat: "Autoras/es en catalán",
        authorsEs: "Autoras/es en castellano",
        projectNameEs: "Título del proyecto en castellano",
        projectNameCat: "Título del proyecto en valenciano",
        year: "Año",
        status: "Estado",
        tipology: "Tipología",
        projectShortDescription: "Descripción del proyecto",
        projectShortDescriptionCat: "Descripción del proyecto en valenciano",
        projectShortDescriptionEs: "Descripción del proyecto en castellano",
        projectCoordinates: "Coordenadas del proyecto",
        generatePassword: "Genera la contraseña para tu cuenta",
        resetPassword:
          "Indica tu correo electrónico para restablecer tu contraseña",
        previous: "Volver",
        next: "Continuar",
        login: "Inicio de sesión",
        loginAction: "Iniciar sesión",
        email: "Correo electrónico",
        password: "Contraseña",
        forgotPassword: "¿Has olvidado tu contraseña?",
        noAccount: "Si aún no tienes cuenta, ",
        noAccountLink: "regístrate aquí",
        profileQuestion: "¿Cuál es tu perfil?",
        profileCreator: "Perfil creativo",
        profileInstitution: "Perfil institucional",
        jobQuestion: "¿Cuál es tu ocupación?",
        student: "Estudiante",
        professional: "Profesional",
        company: "Empresa",
        provinceQuestion: "¿A qué provincia perteneces?",
        personalDataQuestion: "Indícanos tus datos personales",
        alicante: "Alicante",
        castellon: "Castellón",
        valencia: "Valencia",
        nameAndSurname: "Nombre y apellidos",
        companyName: "Nombre de la empresa",
        pronouns: "Pronombres",
        pronounHim: "Él",
        pronounHer: "Ella",
        pronounThey: "Elle",
        municipalityQuestion:
          "Indicanos en el municipio en el qué te encuentras",
        municipalitySelect: "Selecciona tu municipio",
        finalDataQuestion: "Indícanos tus datos personales",
        emailQuestion: "Por último, indícanos tu correo electrónico",
        newPassword: "Nueva contraseña",
        repeatPassword: "Repite la contraseña",
        meetQuestion: "¿Cómo conociste la plataforma del CUG?",
        meetAnswer1: "Redes sociales",
        meetAnswer2: "Por mi trabajo",
        meetAnswer3: "Por recomendación",
        meetAnswer4: "Otra razón",
        thanksRegister: "¡Muchas gracias por registrarte!",
        thanksRegister2:
          "En breves recibiras un correo por parte del equipo de CUG para poder generar una contraseña y subir proyectos",
        backToHome: "Ir a la página de inicio",
        thanksUpload: "¡Te agradecemos que hayas subido un proyecto!",
        seeProfile: "Ver perfil",
        uploadProject: "Subir proyecto",
        lookForQuestion: "¿Qué buscas en la plataforma?",
        completeData: "Completa los datos resumen de tu proyecto",
        maxCharacters200: "(200 caracteres máx)",
        maxCHaracters300: "(300 caracteres má)",
        maxCharacters2000: "(2000 caracteres máx)",
        projectTitle: "Título del proyecto",
        projectYear: "Año",
        projectTipology: "Tipología del proyecto",
        projectState: "Estado del proyecto",
        projectDescription: "Descripción del proyecto",
        projectSelectFiles: "Selecciona archivos",
        maxFilesOne: "(1 máx)",
        maxFilesThree: "(3 máx)",
        maxFilesTen: "(10 máx)",
        recognitionQuestion:
          "¿Tu proyecto ha sido reconocido por la Generalitat Valenciana?",
        recognitionDescription:
          "¡Háznoslo saber para poner tu reconocimiento en la plantilla de proyecto!",
        edition: "Edició",
        recognition: "Reconocimiento",
        recognitionLink: "Link a la página del proyecto",
        coordinatesHelpQuestion: "¿Cómo obtener las coordenadas?",
        coordinatesHelpText1:
          "En google maps, colocar la localización del proyecto. ",
        coordinatesHelpText2:
          "Hacer click derecho sobre el pin de localización y copiar las coordenadas.",
        projectMoreInfoUpload: "Añade más información sobre tu proyecto",
        projectMoreInfoDescription:
          "(Esta información se convertirá en un pdf descargable)",
        descriptiveText: "Texto descriptivo",
        projectLink: "Link del proyecto",
        projectDescriptionEs: "Descripción del proyecto en castellano",
        projectDescriptionCat: "Descripción del proyecto en valenciano",
        emailRequired: "El correo electrónico es obligatorio",
        emailInvalid: "El correo electrónico no es válido",
        passwordRequired: "La contraseña es obligatoria",
        passwordInvalid: "La contraseña no es válida",
        passwordConfirmRequired:
          "La confirmación de la contraseña es obligatoria",
        passwordMinLength: "La contraseña debe tener al menos 8 caracteres",
        passwordMaxLength: "La contraseña no puede superar los 20 caracteres",
        passwordUppercase: "La contraseña debe tener al menos una mayúscula",
        passwordLowercase: "La contraseña debe tener al menos una minúscula",
        passwordNumber: "La contraseña debe tener al menos un número",
        passwordSpecialCharacter:
          "La contraseña debe tener al menos un carácter especial",
        passwordConfirmMatch: "Las contraseñas no coinciden",
        municipalityRequired: "El municipio es obligatorio",
        fullNameRequired: "El nombre y apellidos son obligatorios",
        fullNameMaxLength:
          "El nombre y apellidos no pueden superar los 50 caracteres",
        pronounsRequired: "Los pronombres son obligatorios",
        companyRequired: "El nombre de la empresa es obligatorio",
        companyMaxLength:
          "El nombre de la empresa no puede superar los 50 caracteres",
        projectShortDescriptionMax:
          "La descripción del proyecto no puede superar los 300 caracteres",
        requiredField: "Campo obligatorio",
        uploadMainImage: "Cargar imágen principal",
        uploadImage: "Cargar imágen",
      },
      home: {
        main: {
          1: "Incorporar la <em>perspectiva de género</em> en el entorno que habitamos conecta la ",
          2: "sostenibilidad* ",
          3: "y las",
          4: " infraestructuras*",
          5: " necesarias para una ciudad de",
          6: "espacios comunes* ",
          7: "considerando la",
          8: "habitabilidad* ",
          9: "desde la",
          10: "transversalidad*",
        },
        guide: {
          title: "Descarga nuestra guía",
          description:
            "Puedes descargar el pdf de nuestra <b>guía para incorporar la perspectiva de género en actuaciones urbanas.</b> En esta puedes profundizar más sobre nuestras tipologías de proyectos.",
          button: "Descargar guía",
          guide: "Guía",
        },
        walkthrough: {
          1: {
            title: "Explora nuestro archivo",
            description:
              "<b>Archivo de Proyectos.</b> Dentro del archivo se pueden visualizar proyectos subidos por los perfiles creativos e institucionales relacionados con nuestras líneas de trabajo.",
            button: "Accede aquí",
          },
          2: {
            title: "Localiza proyectos",
            description:
              "<b>Geolocalización.</b> Cada uno de los proyectos se pueden ubicar dentro de nuestro mapa geolocalizador de la Comunidad Valenciana.",
            button: "Accede aquí",
          },
          3: {
            title: "Regístrate y participa",
            description:
              "<b>Crea tu cuenta.</b> Para poder formar parte de la comunidad y  subir proyectos, es necesario la creación de una cuenta. Esto permitirá interactuar con el resto de personas que estén registradas en nuestra web.",
            button1: "Registrarme",
            button2: "inicia sesión",
          },
        },
        info: {
          1: {
            title: "¿Qué es CUG?",
            description1:
              "La nueva <b>CUG Cátedra Urbanisme i Gènere</b> es fruto de un convenio entre GVA y UPV para difundir y facilitar el acceso a la noción y proceso de incorporación de la perspectiva de género en el entorno urbano que habitamos compartidamente.",
            description2:
              "El objetivo principal de la cátedra es actuar de interfaz para que el conocimiento disponible se pueda aplicar de modo práctico en nuestras ciudades y poblaciones, y simultáneamente, las experiencias reales sirvan para generar nuevo conocimiento aplicable que se pueda diseminar. Es en el trenzado de práctica y teoría, con las personas implicadas, donde se espera un método de trabajo más eficaz.",
          },
          2: {
            title: "¿Por qué existimos?",
            description:
              "<p>La iniciativa nace con un carácter abiertamente participativo, como un punto de encuentro de opiniones y referentes sobre la perspectiva de género en el urbanismo.</p><p> Una de las actividades será la difusión de la guía para la aplicación de la perspectiva de género en actuaciones urbanas de GVA, para facilitar su aplicación progresiva en los entornos donde vivimos, así como para recoger distintas posibilidades que puedan ser incorporadas a dicha guía en un futuro</p>",
          },
        },
        worklines: {
          title: "Líneas de trabajo",
          description:
            "Contenidos básicos sobre los que se debería reflexionar y debatir con la finalidad de incorporar la perspectiva de género en el entorno construido.",
        },
        download: {
          title: "Descarga Guía GVA",
          description:
            "Puedes descargar el pdf de <b>nuestra guía para incorporar la perspectiva de género en actuaciones urbanas</b>. En esta puedes profundizar más sobre nuestras tipologías de proyectos.",
          button: "Descargar",
        },
        contact: {
          socials: {
            title: "Síguenos en RR.SS",
            description:
              "Estate al día de conferencias, eventos y noticias relacionadas con la cátedra.",
          },
          mail: {
            title: "Contacta con CUG",
            description:
              "Si tienes alguna propuesta, duda o comentario, contacta con el siguiente enlace.",
            button: "Contacta con nosotros",
          },
        },
      },
      about: {
        title: "¿Qué es CUG?",
        youtube: {
          title: "Nuestro canal de Youtube",
          description:
            "En este canal podrás encontrar vídeos sobre la CUG, desde nuestras actividades hasta nuestras colaboraciones con otras entidades.",
        },
        blog: {
          title: "Visita nuestro blog",
          description:
            "Estate al día con nuestras últimas novedades y proyectos en nuestro blog de wordpress",
          button: "Ir a nuestro Blog",
        },
        contact: {
          title: "Conéctate y construyamos ciudades igualitarias",
        },
        team: {
          title: "Comisión de seguimiento UPV-GVA 2024",
          gva: `<li>Miguel Ángel Ivorra, Director general de Urbanismo, Paisaje y Evaluación Ambiental</li>
          <li>Emilio Argente, Subdirector de Urbanismo</li>
          <li>Caridad García, Servicio de Información y Análisis Urbanístico</li>`,
          upv: `<li>Ivan Cabrera, Director de la ETS Arquitectura</li>
          <li>Ana Portalés, Subdirectora de Cátedras de Empresa y Comunicación ETSA (en representación de la Vicerrectora de Empleo y Formación Permanente, M. Dolores Salvador)</li>
          <li>Eva Álvarez, directora CUG</li>
          <li>Carlos Gómez, director adjunto CUG (con voz y sin voto)</li>`,
        },
      },
      download: {
        cooldown: "Por favor, espera antes de descargar de nuevo.",
      },
      archive: {
        title: "Archivo",
        subtitle:
          "<span>de proyectos con</span>perspectiva de</br>urbanismo y género",
        noResults: "No hay resultados",
      },
      contact: {
        title: "Contacta",
        subtitle: "¿Quieres ponerte en contacto con nuestro equipo?",
        subtitle2: "¡Envíanos un mensaje!",
        nameAndSurname: "Nombre y Apellidos",
        email: "Correo electrónico",
        message: "Mensaje",
        phone: "Teléfono",
        subject: "Asunto",
        send: "Enviar",
        optional: "Opcional",
        greetings: "¡Gracias por ponerte en contacto!",
        greetings2: "Mensaje envíado. En breve nos comunicaremos contigo.",
      },
      map: {
        modal: {
          helpTitle: "Ayuda",
          help1:
            "Haga clic en cualquier chincheta de ubicación en el mapa para mostrar más información sobre el proyecto.",
          help2:
            "Para acercarse o alejarse simplemente desplácese o utilice los botones de zoom situados en la parte inferior derecha del mapa.",
        },
        seeProject: "Ver proyecto",
      },
      admin: {
        menu: {
          items: [
            "Inicio",
            "Proyectos",
            "Mensajes",
            "Estadísticas",
            "Usuarios",
          ],
        },
        saved: "Guardados",
        created: "Creados",
        administrative: "Administrativo",
        uploadNewProject: "Subir nuevo proyecto",
        newConversation: "Nueva conversación",
        searchInMessages: "Buscar en mensajes",
        edit: "Editar",
        writeAMessage: "Escribe un mensaje",
        send: "Enviar",
        back: "Volver",
        cancel: "Cancelar",
        save: "Guardar",
        accept: "Aceptar",
        pickColor: "Elegir color",
        panels: {
          yourZoneActivity: "Actividad en tu zona",
          notifications: "Notificaciones",
          insightsResume: "Resumen de estadísticas",
          insights: "Estadísticas",
          yourProjects: "Tus proyectos",
          yourMessages: "Tus mensajes",
          messages: "Mensajes",
          newConversation: "Nueva conversación",
          projectRecommendation: "Recomendación de proyectos",
          lastIncorporation: "Últimas incorporaciones",
          approvedProjects: "Proyectos aprobados",
          pendingProjects: "Proyectos pendientes",
          users: "Usuarios",
          for: "Para...",
          startChat: "Iniciar chat",
        },
        insights: {
          visualizations: "Visualizaciones",
          saved: "Guardados",
          likes: "Likes",
          comments: "Comentarios",
          yourProjects: "Tus proyectos",
        },
        notifications: {
          approved: "ha aprobado tu proyecto",
          rejected: "ha rechazado tu proyecto",
          saved: "ha guardado tu proyecto",
          liked: {
            prepend: "A",
            append: "le ha gustado tu proyecto",
          },
          commented: "ha comentado tu proyecto",
          timestamp: {
            prepend: "Hace",
            seconds: "segundos",
            minutes: "minutos",
            hours: "horas",
            days: "días",
            months: "meses",
            years: "años",
            more: "más de un año",
          },
        },
        projects: {
          published: "Aprobado",
          pendant: "Pendiente",
          edit: "Editar",
        },
      },
    },
  },
};

export default messages;
