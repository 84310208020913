<template>
  <div
    class="page page-home"
    v-if="backgroundImage()"
    :style="{ backgroundImage: `${backgroundImage()}` }"
  >
    <section class="hero-section">
      <div class="hero-section--inner">
        <p class="hero-section--inner--text">
          <span v-html="this.$t('message.home.main.1')"></span>

          <button class="cat-1 cat-link" @click="showOverlay('SOSTENIBILIDAD')">
            {{ $t("message.home.main.2") }}
          </button>
          {{ $t("message.home.main.3") }}
          <button class="cat-2 cat-link" @click="showOverlay('INSTALACIONES')">
            {{ $t("message.home.main.4") }}</button
          >{{ $t("message.home.main.5") }}
          <button
            class="cat-3 cat-link"
            @click="showOverlay('ESPACIOS_COMUNES')"
          >
            {{ $t("message.home.main.6") }}
          </button>
          {{ $t("message.home.main.7") }}
          <button class="cat-4 cat-link" @click="showOverlay('SEGURIDAD')">
            {{ $t("message.home.main.8") }}</button
          >{{ $t("message.home.main.9") }}
          <button class="cat-5 cat-link" @click="showOverlay('SOCIAL')">
            {{ $t("message.home.main.10") }}
          </button>
        </p>
      </div>
    </section>
    <section
      class="guide-section flex w-full flex-col lg:flex-row items-start gap-16 shrink-0 bg-black p-6 py-12 lg:py-16 lg:px-20 mt-20 text-white overflow-hidden"
    >
      <div
        class="guide__copy flex flex-col items-start gap-6 w-full lg:max-w-[500px] lg:w-[33%] lg:min-w-[300px]"
      >
        <h3
          class="guide__copy--title text-[24px] lg:text-[32px] font-semibold leading-[normal]"
        >
          {{ $t("message.home.guide.title") }}
        </h3>
        <p
          class="guide__copy--description text-justify text-base font-semibold leading-6"
          v-html="$t('message.home.guide.description')"
        ></p>
        <div
          class="guide__buttons w-full lg:w-auto flex flex-col items-center lg:items-start gap-4 lg:gap-6"
        >
          <button
            class="guide__buttons--button flex justify-center items-center gap-2 border bg-white px-4 py-2 rounded-[0.625rem] border-solid border-white text-black text-xl leading-[normal]"
            @click="downloadClick"
          >
            <img src="~@/assets/icons/icon-download-black.svg" alt="" />
            {{ $t("message.home.guide.button") }}
          </button>
          <button
            class="guide__buttons--button flex justify-center items-center gap-2 border bg-white px-4 py-2 rounded-[0.625rem] border-solid border-white text-black text-xl leading-[normal]"
            @click="downloadEnglish"
          >
            <img src="~@/assets/icons/icon-download-black.svg" alt="" />
            Download Guide
          </button>
        </div>
      </div>
      <div
        class="guide__images flex-1 h-full bg-cug-blue relative hidden lg:flex"
      >
        <div class="img-holder absolute w-full top-0 left-0">
          <img
            class="w-full h-full object-cover"
            src="@/assets/images/guide01.webp"
            alt=""
          />
        </div>
        <div class="img-holder absolute w-full top-12 left-48">
          <img
            class="w-full h-full object-cover"
            src="@/assets/images/guide02.webp"
            alt=""
          />
        </div>
        <div class="img-holder absolute w-full top-24 left-96">
          <img
            class="w-full h-full object-cover"
            src="@/assets/images/guide03.webp"
            alt=""
          />
        </div>
      </div>
    </section>
    <section
      class="youtube-section flex lg:flex-row-reverse w-full items-start gap-[63px] shrink-0 bg-cug-yellow p-6 py-12 lg:py-16 lg:px-20 mb-16"
    >
      <div
        class="youtube-copy flex flex-col items-start gap-6 lg:max-w-[400px]"
      >
        <h3
          class="youtube-copy--title text-black text-2xl lg:text-[32px] font-semibold leading-[normal]"
        >
          {{ $t("message.about.youtube.title") }}
        </h3>
        <p
          class="youtube-copy--description self-stretch text-black text-justify text-base font-normal leading-6"
        >
          {{ $t("message.about.youtube.description") }}
        </p>
        <a
          href="https://www.youtube.com/@CUGurbanismoygeneroUPV-GVA"
          target="_blank"
          class="flex justify-center items-center gap-2 border bg-black !px-4 py-2 rounded-[0.625rem] border-solid border-black !text-white text-justify text-xl font-normal leading-[normal]"
        >
          <img src="@/assets/icons/icon-youtube-white.svg" alt="" />
          Youtube
        </a>
      </div>
      <div class="youtube-embed flex-1 aspect-video hidden lg:flex">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/6bSUfSbfMO8?si=0UmGAXnpY0D5a4f3"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </section>
    <walkthrough-section
      v-if="this.$vuetify.breakpoint.width > 1024"
    ></walkthrough-section>
    <walkthrough-section-movil v-else></walkthrough-section-movil>

    <section class="about-cug-section">
      <div class="blob-card left">
        <div class="blob-card--inner">
          <div class="blob-card--inner__header">
            <img
              src="@/assets/images/blob-left-movil.svg"
              alt=""
              v-if="this.$vuetify.breakpoint.width < 1024"
            />
            <img src="@/assets/images/blob-left.svg" alt="" v-else />
            <h4 class="blob-card--inner__header--title">
              {{ $t("message.home.info.1.title") }}
            </h4>
          </div>
          <div class="blob-card--inner__content">
            <p v-html="this.$t('message.home.info.1.description1')"></p>
            <p
              v-if="this.$vuetify.breakpoint.width >= 1024"
              v-html="this.$t('message.home.info.1.description2')"
            ></p>
          </div>
        </div>
      </div>
      <div class="blob-card right">
        <div class="blob-card--inner">
          <div class="blob-card--inner__header">
            <img src="@/assets/images/blob-right.svg" alt="" />
            <h4>{{ $t("message.home.info.2.title") }}</h4>
          </div>
          <div
            class="blob-card--inner__content"
            v-html="this.$t('message.home.info.2.description')"
          ></div>
        </div>
      </div>
    </section>
    <section class="lines-of-work" v-if="this.debug === false">
      <div class="lines-of-work--inner">
        <div class="lines-of-work--header">
          <h4 class="lines-of-work--header__title">
            {{ $t("message.home.worklines.title") }}
          </h4>
          <p class="lines-of-work--header__description">
            {{ $t("message.home.worklines.description") }}
          </p>
        </div>
        <div
          class="lines-of-work--grid"
          v-if="this.$vuetify.breakpoint.width >= 1024"
        >
          <tipology-card
            :cat="$getProjectCategory('SOSTENIBILIDAD')"
          ></tipology-card>
          <tipology-card
            :cat="$getProjectCategory('INSTALACIONES')"
          ></tipology-card>
          <tipology-card
            :cat="$getProjectCategory('ESPACIOS_COMUNES')"
          ></tipology-card>
          <tipology-card
            :cat="$getProjectCategory('SEGURIDAD')"
          ></tipology-card>
          <tipology-card :cat="$getProjectCategory('SOCIAL')"></tipology-card>
        </div>
        <div class="lines-of-work--grid movil" v-else>
          <tipology-card-movil
            :cat="$getProjectCategory('SOSTENIBILIDAD')"
          ></tipology-card-movil>
          <tipology-card-movil
            :cat="$getProjectCategory('INSTALACIONES')"
          ></tipology-card-movil>
          <tipology-card-movil
            :cat="$getProjectCategory('ESPACIOS_COMUNES')"
          ></tipology-card-movil>
          <tipology-card-movil
            :cat="$getProjectCategory('SEGURIDAD')"
          ></tipology-card-movil>
          <tipology-card-movil
            :cat="$getProjectCategory('SOCIAL')"
          ></tipology-card-movil>
        </div>
      </div>
    </section>
    <section
      class="contact-section flex flex-col lg:flex-row w-full items-start gap-16 p-6 lg:px-20 lg:py-16 my-12"
    >
      <div class="flex flex-col items-start gap-6 lg:w-1/3">
        <h3 class="text-[32px] font-semibold leading-[normal]">
          {{ $t("message.home.contact.socials.title") }}
        </h3>
        <p
          class="text-justify text-base font-semibold leading-6 self-stretch text-black"
        >
          {{ $t("message.home.contact.socials.description") }}
        </p>
        <div
          class="flex items-start content-start gap-2 self-stretch flex-wrap"
        >
          <a
            href="https://www.instagram.com/cug_upvgva/"
            target="_blank"
            class="flex justify-center items-center gap-2 border bg-black !px-4 py-2 rounded-[0.625rem] border-solid border-black !text-white text-justify text-xl font-normal leading-[normal]"
          >
            <img src="@/assets/icons/icon-instagram-white.svg" alt="" />
            Instagram
          </a>
          <a
            href="https://www.youtube.com/@CUGurbanismoygeneroUPV-GVA"
            target="_blank"
            class="flex justify-center items-center gap-2 border bg-black !px-4 py-2 rounded-[0.625rem] border-solid border-black !text-white text-justify text-xl font-normal leading-[normal]"
          >
            <img src="@/assets/icons/icon-youtube-white.svg" alt="" />
            Youtube
          </a>
          <a
            href="https://cugnews.wordpress.com/"
            target="_blank"
            class="flex justify-center items-center gap-2 border bg-black !px-4 py-2 rounded-[0.625rem] border-solid border-black !text-white text-justify text-xl font-normal leading-[normal]"
          >
            <img src="@/assets/icons/icon-wordpress-white.svg" alt="" />
            Blog
          </a>
        </div>
      </div>
      <div class="flex flex-col items-start gap-6 lg:w-1/3">
        <h3 class="text-[32px] font-semibold leading-[normal]">
          {{ $t("message.home.contact.mail.title") }}
        </h3>
        <p
          class="text-justify text-base font-semibold leading-6 self-stretch text-black"
        >
          {{ $t("message.home.contact.mail.description") }}
        </p>
        <router-link
          to="/contacte"
          class="flex justify-center items-center gap-2 border bg-black !px-4 py-2 rounded-[0.625rem] border-solid border-black !text-white text-justify text-xl font-normal leading-[normal]"
        >
          <img src="@/assets/icons/icon-send-white.svg" alt="" />
          {{ $t("message.home.contact.mail.button") }}
        </router-link>
      </div>
      <div class="hidden lg:flex h-[228px] justify-center items-center :w-1/3">
        <img
          class="w-full h-full object-cover rounded-2xl overflow-hidden"
          src="@/assets/images/download-guide-image.jpg"
          alt=""
        />
      </div>
    </section>

    <overlay-card ref="overlay"></overlay-card>
  </div>
</template>

<script>
import ProjectService from "@/services/project.services";
import OverlayCard from "@/components/Cards/OverlayCard.vue";
import TipologyCard from "@/components/Cards/TipologyCard.vue";
import TipologyCardMovil from "@/components/Cards/TipologyCardMovil.vue";
import WalkthroughSection from "@/components/Sections/WalkthroughSection.vue";
import WalkthroughSectionMovil from "@/components/Sections/WalkthroughSectionMovil.vue";

export default {
  name: "HomeCug",
  components: {
    OverlayCard,
    TipologyCard,
    TipologyCardMovil,
    WalkthroughSection,
    WalkthroughSectionMovil,
  },
  data() {
    return {
      selectedCat: 0,
      lang: "es",
      debug: false,

      urlImageDesktop: require("@/assets/images/bg-desktop.svg"),
      urlImageMovil: require("@/assets/images/bg-movil.svg"),
      canDownload: true,
      downloadCooldown: 5000, // 5 segundos de cooldown
    };
  },
  mounted() {
    this.getLastProjects();
  },
  methods: {
    showOverlay(cat) {
      const newCat = this.$getProjectCategory(cat);
      if (this.selectedCat === newCat) {
        this.$refs.overlay.toggleOverlay();
      } else {
        this.selectedCat = newCat;
        this.$refs.overlay.updateCat(newCat);
        this.$refs.overlay.toggleOverlay();
      }
    },
    changeLang(lang) {
      this.lang = lang;
    },
    downloadClick() {
      if (this.$root.$i18n.locale === "es") {
        window.open(
          "https://cugnews.wordpress.com/wp-content/uploads/2024/09/aaff_guia-perspectiva-de-genero-en-actuaciones-urbanas_cast.pdf",
          "_blank"
        );
        return;
      } else if (this.$root.$i18n.locale === "ca") {
        window.open(
          "https://politicaterritorial.gva.es/documents/20551182/174929742/3.+GUIA+PERSPECTIVA+DE+G%C3%88NERE+EN+ACTUACIONS+URBANES.pdf/4eedc847-c7c8-51af-6eb5-c068386e55d3?t=1664781422779",
          "_blank"
        );
        return;
      }
    },
    downloadEnglish() {
      window.open(
        "https://politicaterritorial.gva.es/documents/20551182/174929742/4.+GUIA+PERSPECTIVA+DE+GENERO+EN+ACTUACIONES+URBANAS_Ingles.pdf/ebcc94b7-706a-3b53-1611-735459548c9b?t=1675064927039",
        "_blank"
      );
    },
    backgroundImage() {
      if (this.$vuetify.breakpoint.width >= 1024) {
        return "url(" + this.urlImageDesktop + ")";
      }
      return "url(" + this.urlImageMovil + ")";
    },
    getLastProjects() {
      ProjectService.getLastProjects()
        .then((response) => {
          this.lastProjects = response.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.donwload-dossier-button {
  font-size: calc(var(--base) * 2);
  font-weight: 400;
  background-color: rgb(var(--bodyColor));
  color: rgb(var(--bodyBg)) !important;
  padding: calc(var(--base) * 0.2) calc(var(--base) * 2);
  border-radius: calc(var(--base) * 1.25);
  width: 100%;
  display: block;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: calc(var(--base) * 3);
    width: auto;
    display: inline;
  }
}

.page {
  width: 100vw;
  height: 100%;
  min-height: var(--app-height);

  &.page-home {
    // background-image: url(@/assets/images/bg-desktop.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }
}

/**
Hero Section
 */
.hero-section {
  position: relative;
  width: 100vw;
  height: var(--app-height);

  .hero-section--inner {
    position: absolute;
    top: calc(50% + calc(var(--base) * 4));
    transform: translateY(-50%);
    left: 9%;
    width: 80%;

    @media (min-width: 1024px) {
      width: 60%;
      top: calc(50% + calc(var(--base) * 4));
    }
  }
}

p.hero-section--inner--text {
  font-size: calc(var(--base) * 4.25);
  line-height: calc(var(--base) * 5);
  font-weight: 400;
  color: rgb(var(--bodyColor));

  @media (min-width: 1024px) {
    font-size: calc(var(--base) * 7.5);
    line-height: calc(var(--base) * 8.5);
  }

  button.cat-link {
    cursor: pointer;
    font-style: normal;
    display: inline-block;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: calc(var(--base) * 0.4);
      width: 0%;
      position: absolute;
      bottom: calc(var(--base) * 0.5);
      left: 0;
      transition: all ease-out 0.3s;
    }

    &:hover {
      &::after {
        width: calc(100% - calc(var(--base) * 3));
      }
    }

    &.cat-1 {
      color: #90ea42;

      &::after {
        background-color: #90ea42;
      }
    }

    &.cat-2 {
      color: #f9e02b;

      &::after {
        background-color: #f9e02b;
      }
    }

    &.cat-3 {
      color: #a76df2;

      &::after {
        background-color: #a76df2;
      }
    }

    &.cat-4 {
      color: #ef709d;

      &::after {
        background-color: #ef709d;
      }
    }

    &.cat-5 {
      color: #5dc9db;

      &::after {
        background-color: #5dc9db;
      }
    }
  }
}

//About section
.about-cug-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(var(--base) * 5);
  margin-bottom: calc(var(--base) * 7.5);

  @media (min-width: 1024px) {
    position: relative;
    display: block;
    min-height: calc(var(--base) * 112.75);
    margin-top: calc(var(--base) * 10);
    width: 100vw;
    height: 100%;
  }

  .blob-card {
    @media (min-width: 1024px) {
      position: absolute;
    }

    &.left {
      width: 100%;

      @media (min-width: 1024px) {
        left: calc(var(--base) * 12);
        top: calc(var(--base) * 9);
        width: 42vw;
      }

      .blob-card--inner {
        &__content {
          left: 54%;
        }
      }
    }

    &.right {
      width: 100%;

      @media (min-width: 1024px) {
        right: calc(var(--base) * 12);
        top: calc(var(--base) * 37);
        width: 49vw;

        .blob-card--inner {
          &__content {
            // padding: calc(var(--base) * 4.5) calc(var(--base) * 8.25);
            // padding-right: calc(var(--base) * 7.88);
            width: 83%;
            left: 50%;
          }
        }
      }
    }

    .blob-card--inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      width: 100%;

      &__header {
        position: relative;
        z-index: 10;
        width: 100%;
        height: 100%;
        min-height: calc(var(--base) * 12);
        // max-height: calc(var(--base) * 12);
        padding: 0 calc(var(--base) * 2);

        @media (min-width: 1024px) {
          min-height: calc(var(--base) * 24);
          max-height: calc(var(--base) * 24);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;

          @media (min-width: 1024px) {
            object-fit: cover;
          }
        }

        h4 {
          width: 100%;
          text-align: center;
          font-size: calc(var(--base) * 3.75);
          line-height: 1;
          font-weight: 600;
          position: absolute;
          left: 50%;
          top: 45%;
          transform: translate(-50%, -50%);
          z-index: 10;

          @media (min-width: 1024px) {
            font-size: calc(var(--base) * 8);
            top: 43%;
          }

          @media (min-width: 1440px) {
            font-size: calc(var(--base) * 8);
            top: 50%;
          }
        }
      }

      &__content {
        background-color: rgb(var(--bodyBg));
        border-radius: 0 0 calc(var(--base) * 3.75) calc(var(--base) * 3.75);
        padding: calc(var(--base) * 4.5) calc(var(--base) * 2.375);
        padding-bottom: calc(var(--base) * 2.375);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 70%;
        margin-top: calc(var(--base) * -6);
        text-align: justify;

        @media (min-width: 1024px) {
          margin-top: 0;
          position: absolute;
          top: calc(var(--base) * 16);
          transform: translateX(-50%);
          width: 78%;
          padding: calc(var(--base) * 4.5) calc(var(--base) * 2.5);
          padding-right: calc(var(--base) * 8);
          text-align: left;
        }

        @media (min-width: 1440px) {
          top: calc(var(--base) * 18);
        }
      }
    }
  }
}

//Lines of work (Tipology) section
.lines-of-work {
  width: 100%;
  padding: 0;

  @media (min-width: 1024px) {
    padding: 0 calc(var(--base) * 10);
    box-shadow: inset 0 -10px 10px -10px rgba(var(--bodyColor), 0.2);
  }

  .lines-of-work--header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 10;
    padding: calc(var(--base) * 4.25);
    background-color: rgb(var(--bodyBg));
    box-shadow: -4px -1px 20px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    @media (min-width: 1024px) {
      border-radius: calc(var(--base) * 3.75);
      padding: calc(var(--base) * 4.25);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    &__title {
      font-size: calc(var(--base) * 3.75);
      font-weight: 600;
      margin-bottom: calc(var(--base) * 1.25);

      @media (min-width: 1024px) {
        font-size: calc(var(--base) * 6);
        margin-bottom: calc(var(--base) * 1.5);
      }
    }

    &__description {
      font-size: calc(var(--base) * 2);
      line-height: calc(var(--base) * 3);
      width: 80%;

      @media (min-width: 1024px) {
        width: 42%;
      }
    }
  }

  .lines-of-work--grid {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
      padding: 0 calc(var(--base) * 4);
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: calc(var(--base) * 4.38);
    }
  }
}

// Download guide section
.download-guide {
  width: 100%;
  background-color: rgb(var(--bodyBg));
  padding: calc(var(--base) * 4.25) calc(var(--base) * 4.75);

  @media (min-width: 1024px) {
    padding: calc(var(--base) * 10) calc(var(--base) * 19.75);
  }

  .download-guide--inner {
    display: flex;
    flex-wrap: nowrap;
    column-gap: calc(var(--base) * 19.25);

    &__info {
      max-width: calc(var(--base) * 45.88);

      h5 {
        font-size: calc(var(--base) * 4);
        line-height: calc(var(--base) * 4.375);
        font-weight: 600;
        margin-bottom: calc(var(--base) * 3);
      }

      p {
        font-size: calc(var(--base) * 2);
        line-height: calc(var(--base) * 3);
        margin-bottom: calc(var(--base) * 3);
        text-align: justify;
      }

      .download-guide--inner__info--button-container {
        display: flex;
        flex-wrap: nowrap;
        gap: calc(var(--base) * 2);

        a {
          font-size: calc(var(--base) * 2.25);
          font-weight: 400;
          color: rgb(var(--bodyBg));
          background-color: rgb(var(--bodyColor));
          padding: calc(var(--base) * 1) calc(var(--base) * 2);
          display: flex;
          column-gap: calc(var(--base) * 1.25);
          flex-wrap: nowrap;
          border-radius: calc(var(--base) * 1.25);

          @media (min-width: 1024px) {
            font-size: calc(var(--base) * 3);
          }

          &:hover {
            font-weight: 600;
          }

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: calc(var(--base) * 2);
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      border-radius: calc(var(--base) * 3.75);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
</style>
<style lang="scss">
.blob-card--inner__content {
  p {
    font-size: calc(var(--base) * 2) !important;
    line-height: calc(var(--base) * 3) !important;
  }
}
</style>
