<template>
  <section class="walkthrough-section">
    <div class="horizontal-scroll--container">
      <article class="panel walktrhough-section--item first-step">
        <div class="walktrhough-section--item--inner panel--inner">
          <h3 class="walkthrough-section--item--inner__title">
            01. {{ $t("message.home.walkthrough.1.title") }}
          </h3>
          <div class="walkthrough-section--item--inner__text">
            <p v-html="this.$t('message.home.walkthrough.1.description')"></p>
          </div>
          <button
            class="walkthrough-section--item--inner__button mb-2"
            @click="goTo('ArchiveCug')"
          >
            {{ $t("message.home.walkthrough.1.button") }}
            <span class="arrow-right">
              <img src="@/assets/icons/arrow-right.svg" alt="" />
            </span>
          </button>
          <button
            class="guide__buttons--button flex justify-center items-center gap-2 border bg-black px-4 py-2 rounded-[0.625rem] border-solid border-white text-white text-xl leading-[normal]"
            @click="downloadClick"
          >
            {{ $t("message.home.guide.button") }}
            <img src="~@/assets/icons/icon-download-white.svg" alt="" />
          </button>
          <div
            ref="swiper1"
            class="walkthrough-section--item--inner__swiper swiper"
          >
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
              <!-- Slides -->
              <project-card
                v-for="project in projects"
                :key="project.id"
                :title="project.titleCastellano"
                :titleCat="project.titleValencia"
                :author="
                  project.authors[0].nameCastellano
                    ? project.authors[0].nameCastellano
                    : project.authors[0].nameValencia
                "
                :projectId="project.id"
                :authorCat="
                  project.authors[0].nameValencia
                    ? project.authors[0].nameValencia
                    : project.authors[0].nameCastellano
                "
                :cat="
                  $getProjectCategory(project.type)
                    ? $getProjectCategory(project.type)
                    : $getProjectCategory('SOCIAL')
                "
                :stars="project.starsNumber"
                :likes="project.favoritesNumber"
                :views="project.views"
                :img="
                  project.projectMainImages[0]
                    ? project.projectMainImages[0].imageUrl
                    : project.projectMainImages[0]
                "
                class="swiper-slide"
              ></project-card>
            </div>
            <!-- If we need pagination -->
            <!-- <div class="swiper-pagination"></div> -->

            <!-- If we need navigation buttons -->
            <!-- <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div> -->

            <!-- If we need scrollbar
          <div class="swiper-scrollbar"></div> -->
          </div>
        </div>
      </article>
      <article class="panel walktrhough-section--item second-step">
        <div class="walktrhough-section--item--inner panel--inner">
          <h3 class="walkthrough-section--item--inner__title">
            02. {{ $t("message.home.walkthrough.2.title") }}
          </h3>
          <div class="walkthrough-section--item--inner__text">
            <p v-html="this.$t('message.home.walkthrough.2.description')"></p>
          </div>
          <button
            class="walkthrough-section--item--inner__button mb-2"
            @click="goTo('MapCug')"
          >
            {{ $t("message.home.walkthrough.2.button") }}
            <span class="arrow-right">
              <img src="@/assets/icons/arrow-right.svg" alt="" />
            </span>
          </button>
          <button
            class="guide__buttons--button flex justify-center items-center gap-2 border bg-black px-4 py-2 rounded-[0.625rem] border-solid border-white text-white text-xl leading-[normal]"
            @click="downloadClick"
          >
            {{ $t("message.home.guide.button") }}
            <img src="~@/assets/icons/icon-download-white.svg" alt="" />
          </button>
        </div>
      </article>
      <article class="panel walktrhough-section--item third-step">
        <div class="walktrhough-section--item--inner panel--inner">
          <h3 class="walkthrough-section--item--inner__title">
            03. {{ $t("message.home.walkthrough.3.title") }}
          </h3>
          <div class="walkthrough-section--item--inner__text">
            <p v-html="this.$t('message.home.walkthrough.3.description')"></p>
          </div>
          <button
            class="walkthrough-section--item--inner__button"
            @click="goTo('RegisterForm')"
          >
            {{ $t("message.home.walkthrough.3.button1") }}
            <span class="arrow-right">
              <img src="@/assets/icons/arrow-right.svg" alt="" />
            </span>
          </button>
          <div class="inicio-sesion-wrapper mb-2">
            o
            <router-link
              :to="{ name: 'LoginCug' }"
              class="walkthrough-section--item--inner__link"
            >
              {{ $t("message.home.walkthrough.3.button2") }}
            </router-link>
          </div>
          <button
            class="guide__buttons--button flex justify-center items-center gap-2 border bg-black px-4 py-2 rounded-[0.625rem] border-solid border-white text-white text-xl leading-[normal]"
            @click="downloadClick"
          >
            {{ $t("message.home.guide.button") }}
            <img src="~@/assets/icons/icon-download-white.svg" alt="" />
          </button>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import ProjectService from "@/services/project.services";
import ProjectCard from "@/components/Cards/ProjectCard.vue";

import Swiper, { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  name: "WalkthroughSection",
  components: {
    ProjectCard,
  },
  data() {
    return {
      projects: null,
      loading: true,
      canDownload: true,
      downloadCooldown: 5000, // 5 segundos de cooldown
    };
  },
  methods: {
    goTo(view) {
      this.$router.push({ name: view });
    },
    initHorizontalScroll() {
      const container = document.querySelector(".horizontal-scroll--container");
      const panels = this.gsap.utils.toArray(".panel");

      this.gsap.to(panels, {
        xPercent: -100 * (panels.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: container,
          pin: true,
          start: "top 18%",
          scrub: 1,
          end: () => "+=" + container.offsetWidth,
        },
      });
    },
    initSwiper1() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiper1, {
        modules: [Navigation, Pagination],
        // grabCursor: true,
        slidesPerView: "auto",
        spaceBetween: 16,
        centeredSlides: true,
        initialSlide: 1,
        // loop: true,

        // If we need pagination
        pagination: {
          el: ".swiper-pagination",
        },

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // // And if we need scrollbar
        // scrollbar: {
        //   el: '.swiper-scrollbar',
        // },
      });
    },
    getLastProjects() {
      ProjectService.getLastProjects()
        .then((response) => {
          this.projects = response.content;
          this.loading = false;
          this.initSwiper1();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadClick() {
      if (this.$root.$i18n.locale === "es") {
        window.open(
          "https://cugnews.wordpress.com/wp-content/uploads/2024/09/aaff_guia-perspectiva-de-genero-en-actuaciones-urbanas_cast.pdf",
          "_blank"
        );
        return;
      } else if (this.$root.$i18n.locale === "ca") {
        window.open(
          "https://politicaterritorial.gva.es/documents/20551182/174929742/3.+GUIA+PERSPECTIVA+DE+G%C3%88NERE+EN+ACTUACIONS+URBANES.pdf/4eedc847-c7c8-51af-6eb5-c068386e55d3?t=1664781422779",
          "_blank"
        );
        return;
      }
    },
    downloadEnglish() {
      window.open(
        "https://politicaterritorial.gva.es/documents/20551182/174929742/4.+GUIA+PERSPECTIVA+DE+GENERO+EN+ACTUACIONES+URBANAS_Ingles.pdf/ebcc94b7-706a-3b53-1611-735459548c9b?t=1675064927039",
        "_blank"
      );
    },
  },
  mounted() {
    this.initHorizontalScroll();
    this.getLastProjects();
  },
};
</script>

<style lang="scss" scoped>
//Walkthrough Section
.walkthrough-section {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.horizontal-scroll--container {
  display: flex;
  flex-wrap: nowrap;
  column-gap: calc(var(--base) * 5);
  width: calc(57% + 57% + 57%);
  margin-left: calc(var(--base) * 7);
  .panel.walktrhough-section--item {
    width: 57%;
    height: 79vh;
    overflow: hidden;
    padding: calc(var(--base) * 6.25) calc(var(--base) * 14);
    border-radius: calc(var(--base) * 6.25);
    border: solid 2px rgb(var(--bodyColor));
    background: rgba(var(--bodyBg), 0.8);

    .walkthrough-section--item--inner__title {
      font-size: calc(var(--base) * 4);
      line-height: calc(var(--base) * 5);
      font-weight: 600;
      margin-bottom: calc(var(--base) * 3.25);
    }
    .walkthrough-section--item--inner__text {
      font-size: calc(var(--base) * 2);
      line-height: calc(var(--base) * 3);
      font-weight: 300;
      text-align: justify;
      // columns: auto 2;
      margin-bottom: calc(var(--base) * 5.13);
      max-width: calc(var(--base) * 39.38);
    }
    .walkthrough-section--item--inner__button {
      font-size: calc(var(--base) * 3);
      line-height: normal;
      font-weight: 400;
      background-color: rgb(var(--bodyColor));
      color: rgb(var(--bodyBg));
      padding: calc(var(--base) * 1) calc(var(--base) * 2);
      border-radius: calc(var(--base) * 1.25);
      display: flex;
      flex-wrap: nowrap;
      gap: calc(var(--base) * 1.25);
    }
    .walkthrough-section--item--inner__swiper {
      overflow: visible;
    }
    &.first-step {
      .project-card {
        max-height: calc(var(--base) * 48);
      }
    }
    &.second-step {
      background-image: url(@/assets/images/map-bg.svg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      // width:100%;
      // height:100%;
    }
    &.third-step {
      & .walkthrough-section--item--inner__button {
        margin-bottom: calc(var(--base) * 1);
      }
      .inicio-sesion-wrapper {
        font-size: calc(var(--base) * 2.5);
        a {
          color: rgb(var(--bodyColor));
          text-decoration: underline;
          font-style: italic;
        }
      }
    }
  }
}
</style>
